import React from 'react'

import { showAds } from './../utils/utils'
import AdTopLb from './ads/AdTopLb'
import AdBottomLb from './ads/AdBottomLb'

const styles = {
  adContainerTop: {
    // width: testShowAds ? 728 : 'auto',
    // height: testShowAds ? 90 : 'auto',
    // backgroundColor: testShowAds ? 'green' : 'white',
    // width: 728,
    // height: 90,
    marginBottom: 10,
  },
  adContainerBottom: {
    // width: 728,
    // height: 90,
    //backgroundColor: 'yellow',
    marginTop: 10,
  },
}
const Body = props => {
  return (
    <div>
      {showAds() && (
        <div style={styles.adContainerTop}>
          <AdTopLb />
        </div>
      )}
      <div>{props.children}</div>
      {showAds() && (
        <div style={styles.adContainerBottom}>
          <AdBottomLb />
        </div>
      )}
    </div>
  )
}

export default Body
