import React from 'react'

import { primaryColor } from './../config/Colors'

const styles = {
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
  },
  hr: {
    width: 100,
    height: 3,
    marginBottom: 10,
    backgroundColor: primaryColor,
  },
  footerText: {
    color: 'grey',
  },
}

class Footer extends React.Component {
  render() {
    return (
      <div style={styles.container}>
        {/* <div style={styles.hr} /> */}
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <div style={styles.footerText}>All rights reserved mhotspot.com</div>
        </div>
      </div>
    )
  }
}

export default Footer
