import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { Link } from 'gatsby'

import { primaryColor } from './../config/Colors'

const MenuItemsData = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Download',
    path: '/download',
  },
  {
    title: 'Uses',
    path: '/uses',
  },
  {
    title: 'instructions',
    path: '/instructions',
  },
  {
    title: 'Contact',
    path: '/contact',
  },
]

class MenuItems extends React.Component {
  _getStyle(itemPath) {
    if (typeof window !== `undefined`) {
      //to deal with gatsby build error for window
      const currentLocation = window.location.pathname.split(':')[0]
      if (currentLocation === itemPath) {
        return { color: primaryColor }
      }
    }
    return {}
  }

  render() {
    let currentLocation

    if (typeof window !== `undefined`) {
      //to deal with gatsby build error for window
      currentLocation = window.location.pathname.split(':')[0]
    } else {
      currentLocation = '/'
    }

    return (
      <Nav className="ml-auto" navbar key={currentLocation}>
        {MenuItemsData.map(item => (
          <NavItem key={item.title}>
            <NavLink
              tag={Link}
              to={item.path}
              style={this._getStyle(item.path)}
              onClick={this.props.onclick}
            >
              <div style={{ fontSize: 22 }}>{item.title.toLowerCase()}</div>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    )
  }
}

export default MenuItems
