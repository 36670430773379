import { adSensitivePages } from './../config/Constants'

export const getCurrentPageSlug = () => {
  if (typeof window !== `undefined`) {
    //to deal with gatsby build error for window not found
    return window.location.pathname.replace(/\/$/, '') //remove trailing slash
  }
  return 'window undefined error'
}

export const isLocalhost = () => {
  if (typeof window !== `undefined`) {
    //to deal with gatsby build error for window
    if (window.location.hostname === 'localhost') {
      return true
    }
  }
  return false
}

export const showAdsOnPage = () => {
  const currentPage = getCurrentPageSlug()

  if (
    adSensitivePages.indexOf(currentPage) > -1 ||
    currentPage.indexOf('/kb') > -1 // to block /kb/article-slug-mutli
  ) {
    return false
  }

  return true
}

export const blockAdsOnPage = () => {
  if (adSensitivePages.indexOf(getCurrentPageSlug()) === -1) {
    return false
  }
  return true
}

// export const testShowAds = true
export const testShowAds = false

export const showAds = () => {
  // if ((testShowAds || !isLocalhost()) && showAdsOnPage()) {
  //   return true
  // }
  return false
}
