import React from 'react'
import Helmet from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.min.css'

import Header from './Header'
import Footer from './Footer'
import Sidebar from './Sidebar'
import Body from './Body'
import { showAds } from '../utils/utils'

const styles = {
  rootContainer: {
    // backgroundColor: '#fdfdfd',
    padding: 10,
  },
  bodyContainer: {},
  sidebarContainer: {},
}

class Layout extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { children } = this.props

    return (
      <div className="container-fluid" style={{ padding: 0, margin: 0 }}>
        <Helmet>
          <html lang="en" />
          <title>mHotspot - Turn your laptop into wifi hotspot</title>
          <meta
            name="keywords"
            content="virtual wifi router, wifi hotspot creator, windows hotspot software, public wifi hotspot, connectify alternative"
          />
          <meta
            name="description"
            content="Free software to use your windows computer/laptop as virtual wifi router and create a free wifi hotspot."
          />
          <meta name="google-site-verification" content="kfdjj2Jn5KIEQL8XqcR3MzM-5V4fmovEBJUvl6mnRG8" />
        </Helmet>
        <div className="row">
          <div className="col-12">
            <Header />
          </div>
        </div>
        <div className="row" style={styles.rootContainer}>
          <div className="container col-10">
            <div className="row">
              <div className="col-sm-9" style={styles.bodyContainer}>
                <Body>{children}</Body>
              </div>
              <div className="col-sm-3" style={styles.sidebarContainer}>
                <Sidebar />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Footer />
          </div>
        </div>
        {showAds() && (
          <script
            async
            src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          />
        )}
      </div>
    )
  }
}

export default Layout
