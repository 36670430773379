import React from 'react'

import { primaryColor } from './../config/Colors'
import AdSidebarLr from './ads/AdSidebarLr'
import FacebookLikeBox from './widgets/FacebookLikeBox'
import QuickLinks from './widgets/QuickLinks'
import { showAds } from './../utils/utils'

const styles = {
  adContainer: {
    //backgroundColor: 'aqua',
    // width: 336,
    // height: 280,
    marginTop: 10,
    marginBottom: 10,
  },
}

const Sidebar = () => {
  return (
    <div>
      <h3>downloads</h3>
      <p style={{ fontSize: 36, color: primaryColor }}>7000000+</p>
      {showAds() && (
        <div style={styles.adContainer}>
          <AdSidebarLr />
        </div>
      )}
      {/* <FacebookLikeBox /> */}
      {/* <br /> */}
      <QuickLinks />
    </div>
  )
}

export default Sidebar
