import React, { Component } from 'react'
import { Link } from 'gatsby'

const styles = {
  linksContainer: {
    padding: 10,
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridGap: 10,
  },
}
export class QuickLinks extends Component {
  render() {
    return (
      <div>
        <h3>quick links</h3>
        <div style={styles.linksContainer}>
          <Link to="/faq">Frequently Asked Questions</Link>
          <Link to="/kb">Knowledge Base</Link>
          <Link to="/awards">Awards</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/privacy-policy-tnc">Privacy Policy</Link>
          <Link to="/privacy-policy-tnc">Terms & Conditions</Link>
        </div>
      </div>
    )
  }
}

export default QuickLinks
