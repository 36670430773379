import React from 'react'
import { Navbar, NavbarBrand, NavbarToggler, Collapse } from 'reactstrap'
import { Link } from 'gatsby'

import { primaryColor } from './../config/Colors'
import MenuItems from './../components/MenuItems'

const styles = {
  container: {
    width: '100%',
    zIndex: 1,
    backgroundColor: '#ffffff',
  },
  topBar: {
    width: '100%',
    height: 3,
    backgroundColor: primaryColor,
  },
  logo: {
    width: 55,
    height: 55,
  },
  textSitename: {
    color: primaryColor,
    fontSize: 38,
  },
  textCaption: {
    color: 'gray',
    fontSize: 14,
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
}

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  _toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.topBar} />
        <Navbar color="faded" light expand="md">
          <NavbarBrand tag={Link} to="/">
            <div style={styles.headerTextContainer}>
              <div style={styles.textSitename}>mHotspot</div>
              <div style={styles.textCaption}>SHARING IS SO SIMPLE</div>
            </div>
          </NavbarBrand>
          <NavbarToggler
            style={{
              border: 0,
            }}
            onClick={() => this._toggle()}
          />
          <Collapse isOpen={this.state.isOpen} navbar>
            <MenuItems onclick={() => this._toggle()} />
          </Collapse>
        </Navbar>
      </div>
    )
  }
}

export default Header
