import React from 'react'
import AdSense from 'react-adsense'

const AdTopLb = () => {
  return (
    <AdSense.Google client="ca-pub-0654853136410544" slot="5904395819" />
    // <div>
    //   <script
    //     async
    //     src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
    //   />
    //   {/* mh_gj_top_lb */}
    //   <ins
    //     class="adsbygoogle"
    //     style={{ display: 'inline-block', width: 728, height: 90 }}
    //     data-ad-client="ca-pub-0654853136410544"
    //     data-ad-slot="5904395819"
    //   />
    //   <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    // </div>
  )
}

export default AdTopLb
